@font-face {
  font-family: 'AmericanTypewriter';
  src: url('/assets/fonts/AmericanTypewriter.ttf') format('ttf');
  font-style: normal;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width:100% !important;
  margin: 0 !important;
  padding: 20px 0 0 20px;
  max-width: 100%;
}

.container.left {
  justify-content: left !important;
}

.content-area {
  width:90%;
  padding: 30px;
}

.content-area.center {
  text-align: center;
}

.content-column {
  width:40%;
  padding: 10px;
}

.content-buttons {
  width:20%;
  padding: 10px;
}

.content-row {
  height:35px;
}

.content-data {
  width: 100%;
  padding: 20px 20px 20px 0;
  text-align: center;
}

.header-logo-container {
  display: table;
  height: 60px;
  width: 30%;
  margin: 0 auto;
  float: left;
}

.header-content-logo {
  height: 160px;
  text-align: center;
}

.header-content-nav {
  height: 40px;
  text-align: center;
}

.header-content-logo-mob {
  height: 125px;
  text-align: center;
}

.header-content-nav-mob {
  height: 25px;
  text-align: center;
}

.header-content-label {
  width: 50%;
  text-align: center;
  display: inline-block;
}

.header-content-label-space {
  width: 25%;
  display: inline-block;
}

.header-title-container {
  display: table;
  height: 60px;
  width: 70%;
}

.header-title {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font: 1.5em 'AmericanTypewriter';
}

.header-title-left {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  font: 1.5em Helvetica;
}

.header-nav-container {
  display: table;
  height: 60px;
  width: 20%;
  float: right;
}

.header-nav {
  display: table-cell;
  vertical-align: middle;
  float: right;
  margin-right: 30px;
}

.header-name {
  width: 530px;
  height: 150px;
}

.header-name-mob {
  width: 425px;
  height: 120px;
}

.main-content {
  text-align: center;
  margin: 0 0 20px 0;
}

.main-content-padding {
  text-align: center;
  padding: 20px 0 0 0;
  margin: 0 0 20px 0;
}

.main-content-margin {
  text-align: center;
  margin: 20px 0 20px 0;
}

.live-content-padding {
  text-align: center;
  padding: 20px 0 20px 0;
  margin: 0 0 20px 0;
}

.live-content {
  text-align: center;
  padding: 20px 0 20px 0;
}

.full-page {
  width: 100%;
}

.partial-page {
  width: 75%;
  margin: 0 auto;
}

.partial-page-mobile {
  width: 90%;
  margin: 0 auto;
}

.half-page {
  width: 50%;
  margin: 0 auto;
}

.split-page {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}

.menu-container {
  display: table;
  height: 400px;
  width: 400px;
  position: absolute;
  left: 50%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.menu-content {
  border-radius: 10px;
  border-width: 1px;
  border-color: rgb(0,69,107);
  padding: 40px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

.tile {
  display: block;
    height: 250px;
    width: 250px;
    border: solid;
    border-width: 2px;
    border-color: white;
    color: white;
    font-family: 'AmericanTypewriter';
}

.tile-layout {
  width: 18%;
  height: 300px;
  display: inline-block;
}

.tile-layout-mob {
  width: 90%;
  height: 260px;
  display: inline-block;
}

.tile-layout-mid {
  width: 55%;
  height: 260px;
  display: inline-block;
}

.tile-layout-tab {
  width: 70%;
  height: 260px;
  display: inline-block;
}

.tile-layout-about-mob {
  width: 90%;
  height: 320px;
  display: inline-block;
}

.tile-layout-about-mid {
  width: 70%;
  height: 320px;
  display: inline-block;
}

.tile-full {
  height: 100%;
  width: 100%;
}

.image-about {
  height: 75%;
  width: 75%;
}

.image-about-mob {
  height: 235px;
  width: 235px;
}

.image-gallery-tile {
  height: 90%;
  width: 90%;
}

.image-mob {
  height: 170px;
  width: 70%;
}

.image-social-media {
  height: 50px;
  width: 50px;
  margin: 0 5px 0 5px;
}

.image-social-media-small {
  height: 40px;
  width: 40px;
  margin: 0 5px 0 5px;
}

.div-border {
  border: 1px;
  border-color: white;
  border-style: solid;
}

.div-gallery {
  width: 900px;
  height: 700px;
  margin: 0 auto;
}

/*Audio player css*/
.audio-player {
  background-color: #b7b7b7;
  padding: 0.5rem;
  border-radius: 10px;
  filter: invert(100%);
}

input[type=range].invert-blue-grey {
  -webkit-appearance: none;
  width: 100%;
  margin: 5px 0;
  font-family: 'AmericanTypewriter'; }
input[type=range].invert-blue-grey:focus {
  outline: none; }
input[type=range].invert-blue-grey::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #000000;
  border-radius: 0px;
  border: 0px solid #000000; }
input[type=range].invert-blue-grey::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 1px solid #000000;
  height: 11px;
  width: 11px;
  border-radius: 50px;
  background: #bd7a00;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px; }
input[type=range].invert-blue-grey::-webkit-slider-thumb:hover {
  background: black; }
input[type=range].invert-blue-grey:focus::-webkit-slider-runnable-track {
  background: #000000; }
input[type=range].invert-blue-grey::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  background: #000000;
  border-radius: 0px;
  border: 0px solid #000000; }
input[type=range].invert-blue-grey::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 1px solid #000000;
  height: 11px;
  width: 11px;
  border-radius: 50px;
  background: #bd7a00;
  cursor: pointer; }
input[type=range].invert-blue-grey::-moz-range-thumb:hover {
  background: black; }
input[type=range].invert-blue-grey::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent; }
input[type=range].invert-blue-grey::-ms-fill-lower {
  background: #000000;
  border: 0px solid #000000;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d; }
input[type=range].invert-blue-grey::-ms-fill-upper {
  background: #000000;
  border: 0px solid #000000;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d; }
input[type=range].invert-blue-grey::-ms-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  border: 1px solid #000000;
  height: 11px;
  width: 11px;
  border-radius: 50px;
  background: #bd7a00;
  cursor: pointer;
  height: 1px; }
input[type=range].invert-blue-grey::-ms-thumb:hover {
  background: black; }
input[type=range].invert-blue-grey:focus::-ms-fill-lower {
  background: #000000; }
input[type=range].invert-blue-grey:focus::-ms-fill-upper {
  background: #000000; }
  
  /* #play-icon {
    height: 0.9rem !important;
  }
  #loop-icon {
    height: 0.9rem !important;
  } */